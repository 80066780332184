import { createRequestUrl } from '../../../play-core/utils/request-url-helper';
export const VideosByShowIdApiPath = '/videos-by-show-id';
export const loadVideosByShowId = async (axios, showId, next) => {
  const requestUrl = createRequestUrl(VideosByShowIdApiPath, {
    showId,
    next
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};